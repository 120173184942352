<template>
  <div class="text-center space-y-2">
    <div class="font-semibold text-gray-500">
      {{ $t("COMMON.NO_DATA_AVAILABLE") }}
    </div>
    <div>
      {{ $t("ORCHESTRATION.NO_DATA_TIP") }}
    </div>
  </div>
</template>
